<div class="first-div enter-div">
    <div class="col-md-8 text-center mx-auto">
        <div class="justify-content-center align-items-center form-div">
            <div class="col-md-6">
                <h1 class="header">תודה על שהשתמשת בשירות נתיב לטכנאים</h1>
                <div class="responsive-div">
                    <a href="/tech" class="btn-nativ form-btn square-btn"> כניסה נוספת</a>
                    <a href="/" class="btn-nativ form-btn square-btn">יציאה</a>
                </div>
            </div>
        </div>
    </div>
</div>