<div class="first-div enter-div">
    <div class="col-md-8 text-center mx-auto">
        <div class="justify-content-center align-items-center form-div">
            <div class="col-md-5">
                <form [formGroup]="loginForm" class="home-form text-center"
                    (ngSubmit)="onSubmit(loginForm.value,modal)">
                    <h5 class="form-header">כניסה למערכת - טכנאים</h5>
                    <div class="form-group">
                        <div class="input-container">
                            <i class="fa fa-user icon"></i>
                            <input formControlName="username" type="text" (focus)="onFocusInput($event)"
                                class="form-control text-right" id="username"
                                placeholder="{{ 'form.username' | translate }}"
                                [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">
                                    {{ 'form.validation.emptyUsername' | translate }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row or">או</div>
                    <div class="form-group">
                        <div class="input-container">
                            <i class="fa fa-lock icon"></i>
                            <input type="text" formControlName="phonenumber" class="form-control" id="phonenumber"
                                (focus)="onFocusInput($event)"
                                [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }"
                                placeholder="{{ 'form.phonenumber' | translate }}">
                            <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                <div *ngIf="f.phonenumber.errors.required">{{ 'form.validation.emptyPhonenumber' |
                                    translate }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="!authenticated" class="invalid-submit">{{authenticatedError}}</div>
                            <button [disabled]="!enableSubmit" type="submit" class="btn-nativ form-btn square-btn">{{
                                'global.enter' | translate
                                }}</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>

<ng-template #modal let-modal class="modal-md" let-c="close" let-d="dismiss">
    <app-verify-code-modal [formData]="formData" [docId]="docId" [c]="c" [d]="d">
    </app-verify-code-modal>
</ng-template>